<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <b-button class="history-button" variant="secondary" size="sm" @click="state.historyShow = true">
            <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
            {{ $t('button.history') }}
          </b-button>
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('department.title').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('department.title').toLowerCase()]) }}</span>
        </div>
      </template>
      <template v-else>

        <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        

        <b-form-group v-if="isDepartmentVisible" :label="$t('department.field.parent_department')" label-for="parent">
          <b-input-group>
            <b-form-input id="parent" type="text"
              v-model="parent.name" readonly>
            </b-form-input>
            <b-input-group-append v-if="!isDepartmentReadOnly">
              <b-button size="sm" @click="modalRemove">{{ $t('button.remove') }}</b-button>
              <b-button v-if="canList()" size="sm" variant="info" @click="modalSelect">{{ $t('button.select') }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        
        <div class="container pl-0">
          <b-row>
            <b-col v-if="isNameVisible" cols="12" md="8" class="pr-0">
              <b-form-group :label="$t('department.field.name')" label-for="name">
                <b-input-group>
                  <b-form-input id="name" type="text"
                    :data-vv-as="$t('department.field.name')"
                    data-vv-name="department.name"
                    :maxlength="maxNameLength"
                    data-vv-delay="500"
                    v-model="department.name" 
                    v-validate="{ required: true }"
                    :readonly="isNameReadOnly"
                    :autofocus="true"
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'department.name')"
                    trim 
                    @keydown.native="keydownHandler">
                  </b-form-input>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('department.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['name'] != null">
              <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="department[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
              <b-form-group :label="$t('field.identifier')" label-for="identifier">
                <b-input-group>
                  <b-form-input id="identifier" type="text"
                    :data-vv-as="$t('field.identifier')"
                    data-vv-name="department.identifier"
                    :maxlength="maxIdentifierLength"
                    v-model="department.identifier" 
                    :readonly="isIdentifierReadOnly"
                    trim>
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['identifier'] != null">
              <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="department[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="customFieldMap['default'] != null">
              <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="department[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
            <b-col v-if="isTagVisible" cols="12" md="8" class="pr-0">
              <b-form-group>
                <TagList :holderId="id" :tags="tags" @modified="tagsModified" :readOnly="isTagReadOnly"/>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['tags'] != null">
              <b-col v-for="(field, index) in customFieldMap['tags']" :key="'tags'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="department[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isColorVisible" cols="12" md="4" class="pr-0">
              <Color :disabled="isColorReadOnly" v-model="department.color" :update="updatedColor"/>
            </b-col>
            
            <template v-if="customFieldMap['color'] != null">
              <b-col v-for="(field, index) in customFieldMap['color']" :key="'color'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="department[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </div>
        
        <b-form-group v-if="isNoteVisible">
          <NoteList :readOnly="isNoteReadOnly" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
        </b-form-group>

        <template v-if="customFieldMap['notes'] != null">
          <div class="container pl-0">
            <b-row>
              <b-col v-for="(field, index) in customFieldMap['notes']" :key="'notes'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="department[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>
      </template>
      
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="viewStaff" style="margin-right: auto" v-if="exists && canView('STAFF')">
          {{ $t('button.view_staff') }}
        </b-button>
        <b-button v-if="!isAccessDenied && (canEdit() || !exists)" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <DepartmentSelectorModal :exclude="department.uuId" :company="company" :hideStaffCount="true" :editor="false" :preselected="parent.path ? parent.path : parent.uuId" :title="$t('department.title_parent_selector')" :show.sync="modalShowSelector" @ok="modalSuccessSelector" :forceSingleSelection="true" mode="SELECT"/>
    <StaffSelectorModal v-if="showStaffSelector" mode="MANAGE" :departments="[department]" :companies="company ? [company] : []" :show.sync="showStaffSelector"/>
   
    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" entityType="DEPARTMENT" :customFields="customFields" links="NOTE,TAG"/>
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
    </template>    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import { persistNotes } from '@/components/Note/script/crud-util';
import  { updateTags } from '@/components/Tag/script/crud-util';
import { strRandom, processRegExp } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';

import { departmentService, companyService,
         departmentLinkTagService } from '@/services';
import { removeDeniedProperties } from '@/views/management/script/common';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';

export default {
  name: 'DepartmentModal',
  components: {
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    Color: () => import('@/components/Color/Color.vue'),
    StaffSelectorModal: () => import('@/components/modal/StaffSelectorModal'),
    CustomField: () => import('@/components/CustomField.vue')
  },
  props: {
    id:        { type: String,   default: `DEPARTMENT_NEW_${strRandom(5)}` },
    parentData:{ type: [String, Object],   default: null },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true },
    queryParent: { type: Boolean, default: false },
    masterCompany: { type: Object, default: null },
    companyData: { type: Array, default: null }
  },
  data() {
    return {
      permissionName: 'DEPARTMENT',
      modelInfo: null,
      alertMsg: null,
      alertMsgDetails: [],
      modalShowSelector: false,
      showStaffSelector: false,
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false,
        autoScheduleExpanded:false,
        historyShow:         false,
        noteShow:            false
      },
      department: {
        uuId:               null,
        name:               null,
        identifier:         null,
        color:              null
      },
      parent: {
        uuId:               null,
        name:               null
      },
      tags: [],
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      updatedColor: null,
      isAccessDenied: false,
      // for restricting the selection of the parent to just the same company
      company: null,
      
      customFields: [],
      customFieldMap: {},

      restrictedRequiredField: null
    }
  },
  beforeCreate() {
    this.$options.components.DepartmentSelectorModal = require('./DepartmentSelectorModal.vue').default;
  },
  created() {
    this.getModelInfo();
    this.originDepartment = null;
    this.originTags = [];
    this.originNotes = [];
    if(this.id == null || this.id.indexOf('DEPARTMENT_NEW_') != -1) {
      // new department, set the parent to the selected department
      if (this.parentData != null) {
        if (typeof this.parentData === 'object') {
          this.parent.name = this.parentData.name;
          this.parent.uuId = this.parentData.uuId;
          this.parent.type = this.parentData.type;
          this.parent.path = this.parentData.path;
          this.department.color = this.parentData.color;
        }
        else {
          const self = this;
          departmentService.query([{ uuId: this.parentData}]).then((response) => {
            const listName = response.data.jobCase;
            const data = response.data[listName] || [];
            if(data.length > 0) {
              self.parent.uuId = data[0].uuId;
              self.parent.name = data[0].name;
              self.department.color = data[0].color;
            }
          })
          .catch((e) => {
            this.httpAjaxError(e);
          });
        }
      }
      else {
        this.parent.name = '';
        this.parent.uuId = null;
        delete this.parent.type;
        this.parent.path = null;
        this.department.color = this.$store.state.company.color;
      }
    }
    this.fieldValidateUtil = fieldValidateUtil;
  },
  mounted() {
    this.state.modalShow = this.show;
    if (this.show) {
      this.processWhenShowModal(true)
    }
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originDepartment = null;
    this.originTags = null;
    this.originNotes = null;
  },
  computed: {
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    componentId() {
      return `DEPARTMENT_FORM_${this.id}`;
    },
    exists() {
      return this.id && !this.id.startsWith('DEPARTMENT_NEW_');
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'department.name');
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    labelTitle() {
      return this.title? this.title: this.$t('department.title_new');
    },
    maxNameLength() {
      var values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "name";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    isDepartmentVisible() {
      return this.canView('COMPANY', ['DEPARTMENT']) && this.canView(this.permissionName, ['DEPARTMENT'])
    },
    isDepartmentReadOnly() {
      return this.isReadOnly || !(this.canEdit('COMPANY', ['DEPARTMENT']) && this.canEdit('DEPARTMENT', ['DEPARTMENT']))
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    isIdentifierVisible() {
      return (!this.exists && this.canAdd(this.permissionName, ['identifier']) && this.canView(this.permissionName, ['identifier']))
      || (this.exists && this.canView(this.permissionName, ['identifier']))
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isColorVisible() {
      return (!this.exists && this.canAdd(this.permissionName, ['color']) && this.canView(this.permissionName, ['color'])) 
      || (this.exists && this.canView(this.permissionName, ['color']))
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.processWhenShowModal(newValue)
      }
    }
  },
  methods: {
    async processWhenShowModal(newValue) {
      await getCustomFieldInfo(this, 'DEPARTMENT');
      this.$validator.resume();
      if (this.customFields.length == 0) {
        this.customFieldMap = {};
      } else {
        this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
      }
      this.state.modalShow = newValue;
      this.state.autoScheduleExpanded = false;
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.notes = [];
      this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
      this.restrictedRequiredField = null;
      if(this.id.indexOf('DEPARTMENT_NEW_') === -1) {
        this.departmentGet(this.id);
      } else {
        if (newValue) {
          const requiredFields = ['name']
          const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
          if (requiredCustomFields.length > 0) {
            requiredFields.push(...requiredCustomFields);
          }
          let result = this.canView2(this.permissionName, [...requiredFields, 'DEPARTMENT']);
          if (result.val) {
            result = this.canAdd2(this.permissionName, requiredFields)
          }

          if (result.val && 
                !(this.canView('COMPANY', ['DEPARTMENT']) 
                && this.canEdit('COMPANY', ['DEPARTMENT']) 
                && this.canEdit(this.permissionName, ['DEPARTMENT']))) {
            result = { val: false, restrictedProp: 'COMPANY' }
          }
          
          if (result.restrictedProp != null) {
            this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
          }

          if (result.val) {
            this.isAccessDenied = false;
          } else {
            this.isAccessDenied = true;
          }
           
        } else {
          this.isAccessDenied = false;
        }
        this.resetDepartmentProperties();
      }
    },
    getDisplayNameOfProperty(val) {
      if (val == 'DEPARTMENT' || val =='COMPANY') {
        return this.$t('department.field.parent_department');
      } else {
        const found = this.customFields.find(i => i.name == val);
        if (found != null) {
          return found.displayName;
        }
        return  this.$t(`department.field.${val}`);
      }
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "DEPARTMENT"}).then(value => {
        self.modelInfo = value.DEPARTMENT.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    keydownHandler(event) {
      if (event.which === 13) {
        // The key pressed was the enter key
        this.ok();
      }
    },
    findParentCompany(data, id, parent) {
      var ret = false;
      if (typeof data === 'undefined') {
        console.log(data); // eslint-disable-line no-console
      }
      for (var dept of data) {
        if (dept.uuId === id) {
          if (parent !== null) {
            this.company = parent;
            return true;
          }
        }
        
        if (typeof dept.departmentList !== 'undefined') {
          ret |= this.findParentCompany(dept.departmentList, id, dept.type ? dept : parent);
        }
        
        if (typeof dept.companyList !== 'undefined') {
          ret |= this.findParentCompany(dept.companyList, id, dept);
        }
      }
      return ret;
    },
    findParent(data, id, parent) {
      var ret = false;
      if (typeof data === 'undefined') {
        console.log(data); // eslint-disable-line no-console
      }
      for (var dept of data) {
        if (dept.uuId === id) {
          if (parent !== null) {
            this.parent.uuId = parent.uuId;
            this.parent.name = parent.name;
            return true;
          }
        }
        
        if (typeof dept.departmentList !== 'undefined') {
          ret |= this.findParent(dept.departmentList, id, dept);
        }
        
        if (typeof dept.companyList !== 'undefined') {
          ret |= this.findParent(dept.companyList, id, dept);
        }
      }
      return ret;
    },
    async departmentParentGet(id) {
      const self = this;
      this.parent.name = '';
      this.parent.uuId = null;
      const treeData = await departmentService.tree({includeStaff: false}).then((response) => {  
        return response.data;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
      
      const result = self.findParent(treeData, id, null);
      const companyTreeData = self.companyData !== null ? self.companyData : await companyService.tree(true).then((response) => {  
        return response.data[response.data.jobCase];
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return null;
      });
      self.findParentCompany(companyTreeData, id, null);
      if (!result) {
        // check if a company is the parent
        self.findParent(companyTreeData, id, null);
      }
    },
    departmentGet(id) {
      departmentService.query([{ uuId: id}],['TAG', 'NOTE', 'COMPANY']).then((response) => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          this.digestResponse(data[0]);
        }
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.isAccessDenied = true;
          return;
        }
        this.httpAjaxError(e);
      });
    },
    digestResponse(data) {
      const s = this.department;
      for (const key of Object.keys(s)) {
        s[key] = data[key] || null;
      }
      
      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          s[field.name] = data[field.name];
        }
      }
      
      if (this.queryParent) {
        this.departmentParentGet(this.department.uuId);
      }
      else {
        if (this.parentData != null) {
          this.parent.name = this.parentData.name;
          this.parent.uuId = this.parentData.uuId;
        }
        else {
          this.parent.name = '';
          this.parent.uuId = null;
        }
      }
      this.originDepartment = cloneDeep(s);
      
       //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      if (data.noteList && data.noteList.length > 0) {
        const container = this.$refs['comments'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
      }
      
      //Setup Tags data
      if (data.tagList && data.tagList.length > 0) {
        const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
        this.originTags.splice(0, this.originTags.length, ...list);
        this.tags.splice(0, this.tags.length, ...cloneDeep(list));
      }
      
    },
    modalSelect() {
      this.modalShowSelector = true;
    },
    modalRemove() {
      this.parent.uuId = this.masterCompany.uuId;
      this.parent.name = this.masterCompany.name;
      this.parent.type = this.masterCompany.type;
    },
    ok() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        field.showError = false;
        if (!customFieldValidate(field, this.department[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.errors.clear();
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
          this.departmentSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    async departmentSubmit() {
      const data = cloneDeep(this.department);
      
      let mode = 'update';
      if(this.id.indexOf('DEPARTMENT_NEW_') !== -1) {
        delete data['uuId'];
        if (typeof this.parent.type === 'undefined') {
          data['parent'] = this.parent.uuId;
        }
        mode = 'create';
      }
      
      const successMsg = this.$t(`department.${mode}`);
      //Skip updating department if there is no change in department properties.
      let hasChanged = false;
      if (mode != 'create') {
        hasChanged = this.removeUnchangedDepartmentProperties(data);
      }
      let hasError = false;
      let response = null;
      if (mode == 'create' || hasChanged) {
        response = await this.departmentPost(mode, data)
      }

      if (response == null && !this.parentChanged()) {
        //Stop proceeding further when response is null. Failed to create/update department.
        //the error feedback has been handled/prepared in departmentPost()
        return;
      }

      if (mode === 'create' && this.parent.uuId !== null && response != null &&
          response.data.feedbackList.length !== 0 &&
          typeof response.data.feedbackList[0].uuId !== 'undefined') {
        this.department.uuId = response.data.feedbackList[0].uuId;
        
        if (typeof this.parent.type !== 'undefined') {
          await this.linkParent();
        }
      }
      else if (mode === 'update' && this.parentChanged()) {
        // change the parent
        if (this.parentData !== null) {
          await this.unlinkParent(); 
        }
        await this.linkParent();
      }
      
      // save the color in the profile
      this.updatedColor = data.color;
      
      //Notes
      if (!this.isNoteReadOnly) {
        //Remove uuId of new notes before saving
        const notes = cloneDeep(this.notes);
        for (let i = 0, len = notes.length; i < len; i++) {
          if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
            delete notes[i].uuId;
          }
        }
        const noteResult = await persistNotes(data.uuId, this.originNotes, notes);
        if (noteResult.errors.length > 0 || noteResult.errorCodes.length > 0) {
          hasError = true;
          this.alertMsg = this.$t(`department.${mode}_partial`);
          if (noteResult.errorCodes.length > 0) {
            if (noteResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('notes').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
          }
        }
      }
      
      if (!this.isTagReadOnly) {
        const tagResult = await updateTags(this.department.uuId, departmentLinkTagService, this.originTags, this.tags);
        if (tagResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`department.${mode}_partial`);
          if (tagResult.errors.filter(i => i.response != null && i.response.status == 403).length > 0) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('tag.title').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('tag.title').toLowerCase()]))
          }
        }  
      }
      

      if (!hasError) {
        this.$emit('update:show', false);
        this.$emit('success', { msg: successMsg, parent: this.parent });
      }
    },
    parentChanged() {
      // is the parent uuId different
      return (this.parentData === null && this.parent.uuId !== null) ||
                 (this.parentData !== null && this.parentData.uuId !== this.parent.uuId);
    },
    async departmentPost(method, data) {
      this.state.isSubmitting = true;
      removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
      const response = await departmentService[method]([data])
      .then(response => {
        return response;
      })
      .catch(e => {
        this.httpAjaxError(e);
        return null;
      });
      
      this.state.isSubmitting = false;
      return response;
    },
    async companyPost(method, data /**, successMsg */) {
      this.state.isSubmitting = true;

      const result = await companyService[method]([data], 'department')
      .then(() => {
        return true;
      })
      .catch((e) => {
        this.httpAjaxError(e);
      }).then(() => {
        this.state.isSubmitting = false;
        return false;
      });
      
      return result;
    },
    async linkParent() {
      if (this.parent.uuId !== null) {
        const parentData = {};
        parentData['uuId'] = this.parent.uuId;
        parentData['departmentList'] = [{'uuId': this.department.uuId}];
        if (typeof this.parent.type !== 'undefined') {
          await this.companyPost('link', parentData, this.$t('department.link'));
        }
        else {
          await this.departmentPost('link', parentData, this.$t('department.link'));
        }
      }
    },
    async unlinkParent() {
      if (this.parentData !== null) {
        const parentData = {};
        parentData['uuId'] = this.parentData.uuId;
        parentData['departmentList'] = [{'uuId': this.department.uuId}];
        if (typeof this.parentData.type !== 'undefined') {
          await this.companyPost('unlink', parentData, this.$t('department.unlink'));
        }
        else {
          await this.departmentPost('unlink', parentData, this.$t('department.unlink'));
        }
      }
    },
    httpAjaxError(e) {
      const response = e.response;
      let errorMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        errorMsg = this.$t('error.authorize_action');
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        const clue = feedback.clue.trim().toLowerCase();
        if(['missing_argument','cannot_be_blank', 
            'string_limit_exceeded', 'number_limit_exceeded',
            ].includes(clue)) {
          errorMsg = this.$t('error.attention_required');
          const fieldKey = `department.${feedback.args[0]}`;
          const args = [this.$t(`department.field.${feedback.args[0]}`)];
          let clueNotHandled = false;
          switch (clue) {
            case 'missing_argument': //Do nothing. Doesn't need additional argument
            case 'cannot_be_blank':
              break;
            case 'string_limit_exceeded':
            case 'number_limit_exceeded':
              args.push(feedback.args[1]);
              break;
            default:
              clueNotHandled = true;
              errorMsg = this.$('error.internal_server'); //reset the errorMsg to internal_server error.
          }
          if (!clueNotHandled) {
            this.errors.add({
              field: fieldKey,
              msg: this.$t(`error.${clue}`, args)
            });
          }
        }
      }
      this.alertMsg = errorMsg;
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetDepartmentProperties() {
      const keys = Object.keys(this.department);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        
        let customField = this.customFields.find(f => f.name === keys[i])
        if (customField) {
          if (customField.def) {
            this.department[keys[i]] = customField.def;
            continue;
          }
        }
        this.department[keys[i]] = null;
      }
      // set the parent to the selected department
      if (this.parentData != null) {
        if (typeof this.parentData === 'object') {
          this.parent.name = this.parentData.name;
          this.parent.uuId = this.parentData.uuId;
          this.parent.type = this.parentData.type;
        }
        else {
          const self = this;
          this.parent.name = '';
          this.parent.uuId = null;
          departmentService.query([{ uuId: this.parentData}]).then((response) => {
            const listName = response.data.jobCase;
            const data = response.data[listName] || [];
            if(data.length > 0) {
              self.parent.uuId = data[0].uuId;
              self.parent.name = data[0].name;
            }
          })
          .catch((e) => {
            this.httpAjaxError(e);
          });
        }
      }
      else {
        this.parent.name = '';
        this.parent.uuId = null;
      }
      this.originNotes = [];
      this.notes = [];
    },  
    modalSuccessSelector({ details }) {
      this.parent.uuId = details[0].uuId;
      this.parent.name = details[0].name;
      this.parent.type = details[0].type;
      this.parent.path = details[0].path;
      this.department.color = details[0].color ? details[0].color : details[0].company.color;
      this.modalShowSelector = false;
    },
    modalCancelSelector() {
      this.modalShowSelector = false;
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false)
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    removeUnchangedDepartmentProperties(data) {
      //Remove those properties whose value is not changed in provided data against original department.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalDepartment = this.originDepartment;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (originalDepartment[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      
      if (!hasChanged) {
        hasChanged = this.originTags.length !== this.tags.length || this.originTags.filter(t => !this.tags.includes(t)).length !== 0;
      }
      if (!hasChanged) {
        hasChanged = this.originNotes.length !== this.notes.length || this.originNotes.filter(t => !this.notes.includes(t)).length !== 0;
      }
      return hasChanged;
    },
    tagsModified({tags}) {
      this.tags = tags;
    },
    viewStaff() {
      this.showStaffSelector = true;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    }
  }
}
</script>
